.loaderHolder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: rgba(#000000, 0.5);
    left: 0;
    z-index: 9999;
}


