.i_icon {
  font-family: fontaregular;
}

.public-bg {
  background: #484d59;
}

.react-datepicker-wrapper {
  display: flex !important;
  padding: 0;
  border: 0;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: 7px;
}

.react-datepicker__navigation--previous {
  left: 10px;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: 0px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: currentColor;
}

.all_drivers .react-datepicker__header__dropdown--select select {
  min-height: 11px !important;
  font-size: 14px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  /* box-sizing: unset !important;
  display: none; */

  box-sizing: unset !important; 
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2px;
}

.react-datepicker__input-container .form-control {
  height: 100% !important;
}

.form_search .card-footer button {
  min-width: 95px !important;
}

/* Create New Shop Form Button  */
.btn-size {
  width: 90px !important;
}

.form-foot-btn button {
  width: 90px !important;
}

form .model-form-btn button {
  min-width: 82px;
  font-size: 14px;
}

form .model-form-savbtn button {
  min-width: 75px;
  font-size: 14px;
}

.footer-step button {
  width: 90px !important;
}

form .form-group.required .control-label:after {
  content: "*";
  color: #ff3131;
  margin-left: 1px;
}

.admin_form label {
  font-size: 16px;
  color: lightslategray;
}

table thead tr img {
  margin-left: 5px;
}

table thead tr i {
  margin-left: 4px;
  color: darkcyan;
  cursor: pointer;
}

.not_avl {
  color: #ffc107;
  font-weight: 600;
}

.all_drivers .csv-link {
  text-decoration: none;
  color: #fff;
}

.modal-dialog .model-wd {
  min-width: 600px !important;
}

.modal-dialog .modal-body form input {
  height: 42px !important;
  border: 1px solid #ccc;
}

.card .card-body .form-group .form-control {
  height: 42px !important;
  border: 1px solid #ccc;
}

.css-13cymwt-control {
  min-height: 44px !important;
}


.col-sm-6 .bt-mrg {
  margin-top: -2px;
}

/* ######---COnfirm MOdel CSS-----#### */
.modal-confirm {
  max-width: 550px !important;
}

.modal-confirm .modal-content {
  padding: 15px;
  border: none;
  text-align: center;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm .modal-body h5 {
  font-size: 1.14rem;
}

.modal-confirm .modal-body .form-textArea {
  margin-bottom: 25px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-confirm .modal-footer {
  border: none;
  border-radius: 5px;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
  min-width: 75px;
  font-size: 14px;
}

/* No data found CSS */
.all_drivers .table_card .data-not-found {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
}

.modal-body .not-found-data {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body .not-found-data h2 {
  font-weight: normal;
  font-size: 20px;
  color: #645d5d;
}

.all_drivers .table_card .data-not-found h2 {
  font-weight: normal;
  font-size: 25px;
  color: #645d5d;
}

.all_drive_table {
  padding: 0px !important ;
  padding-top: 1rem !important;
}

.all_drive_table table tbody tr i {
  padding-right: 6px;
  cursor: pointer;
}

.unauth-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  padding-left: 250px;
}

.unauth-content h2 {
  color: #000f;
  font-size: 1.8rem;
}

table tbody tr .view-details-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #17a2b8;
}

.modal-content .st {
  text-transform: capitalize;
  padding-left: 30px;
  font-size: 18px;
  text-align: center;
}

.pagination_new .pagination .btn_active {
  color: darkcyan;
}

.sidebar .nav-item {
  cursor: pointer;
}

/* ####--- Track Order with Google Map CSS  ---#### */
.main-content {
  padding-top: 0.5em;
  background: #fff;
}

.order-status-card {
  color: #fff;
  background: #3ea6f3;
  border: 0;
  box-shadow: 0 0.044em 0.624em 0 rgb(42 0 0 / 6%);
}

.card-bottom .user-img {
  width: 35px;
  float: left;
  margin-right: 20px;
}

.card-bottom .user-img img {
  border-radius: 50%;
}

.order-status-card .card-img {
  max-width: 265px;
  margin: 0 auto;
}

.order-status-card .card-title {
  float: none;
  margin-bottom: 0.75rem;
}

.rate-badge {
  position: absolute;
  bottom: -10px;
  left: -1px;
  background: #fff;
  border-radius: 30px;
  width: 36px;
  padding: 1px 0;
  color: #3ea6f3;
  font-weight: 700;
  text-align: center;
  font-size: 0.499em;
}

.status-text {
  max-width: 150px;
  float: left;
}

.status-text p {
  font-size: 0.83em;
  font-weight: 300;
}

.contact-links {
  padding-top: 10px;
  text-align: right;
}

.contact-links a {
  display: inline-block;
  margin-left: 15px;
}

.download-text {
  margin-top: 40px;
}

.track-maps-content {
  text-align: center;
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
}

.all_drivers_map {
  text-align: center;
  width: 100%;
  height: 820px;
  position: relative;
  overflow: hidden;
}

.all_drivers .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #22597c;
  text-align: left !important;
  cursor: pointer;
}

/* ##---Order Details PopUp Model ----*/
.scroler-fixed {
  overflow-y: scroll !important;
}

.scroler-fixed::-webkit-scrollbar {
  width: 0px;
}

.modal--body--scroll {
  min-height: 200px;
  max-height: 500px;
}

.detail--modal--scroll {
  min-height: 200px;
  max-height: 540px;
}

/* ##---- Refresh font Awesome Spiner ---- ## */

.all_drivers .fa_spinner {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.chart-avgtime {
  height: 400px;
}

@media (min-width: 600px) {
  .docs-model {
    max-width: 40% !important;
  }
}

.docs-down-icon {
  cursor: pointer;
  font-size: 20px;
  height: 35px;
}

.modal-content .doc_title {
  text-transform: capitalize;
}

.details--status {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  padding-inline-start: 50px;
}

.fs-18 {
  font-size: 18px;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .details--status {
    padding-inline-start: 0px;
    order: 1;
  }
}

@media (max-width: 767px) {
  .fs-18 {
    font-size: 18px;
  }
}

#example2 thead {
  position: sticky;
  top: 0;
  background: #fff;
  background-color: #edf6f9;
}

table thead {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  background-color: #edf6f9;
}

table .table_checkbox {
  min-height: 15px !important;
  width: 28px;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  top: 3px;
}

table .user_table_checkbox {
  min-height: 15px !important;
  width: 30px;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  top: 2px;
}

.all_drivers .pagination {
  z-index: 0;
  position: relative;
}

.all_drivers .form-group .css-2613qy-menu {
  z-index: 999;
}

.login_details .dropdown-item {
  cursor: pointer;
}

.order_notes_txt p {
  font-size: 17px;
}

form .radio_input {
  width: 15px;
  height: 15px;
}

.chart_card_body {
  padding: 0.5rem;
}

.main-footer {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  height: auto;
}

.main-footer footer_text {
  position: relative;
  top: 10px;
  left: 10px;
  padding: 15px;
}

.new-samll-box {
  display: flex !important;
  flex-wrap: wrap;
  color: #fff !important;
}

.new-samll-box .box-inner {
  width: 50%;
  max-height: 110px;
}

.new-samll-box .box-icon {
  width: 50%;
  text-align: right;
  display: block !important;
}

.new-samll-box .box-image {
  width: 100%;
  max-width: 100px;
}

.new-samll-box .new-box-footer {
  width: 100%;
  margin-top: 10px;
}

.item--wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  width: 100%;
  gap: 20px;
  margin-left: 13px;
}

.item--wrapper .new-samll-box {
  height: 100%;
  margin-bottom: 0;
  position: relative;
  margin-top: 15px;
}

.cs--modal {
  background-color: rgba(000, 000, 000, 0.3);
}

body .gm-ui-hover-effect:focus,
body .gm-ui-hover-effect:focus-visible {
  outline: none !important;
  border: none !important;
}

.navbar .notify--drop {
  min-width: 450px !important;
  max-width: 450px !important;
}

.navbar .dropdown-menu .clear-notifications {
  text-align: center;
  cursor: pointer;
}

.navbar .notifications {
  max-height: 280px;
  overflow-y: scroll;
}

span.dropdown-item.dropdown-header {
  background: #4b545c;
  color: #fff;
}

.dropdown-item.dropdown-footer.clear-notifications {
  background: #edf6f9;
}

.navbar i.fa-sharp.fa-solid {
  font-size: 16px !important;
  cursor: pointer !important;
}

.navbar .notifications .dropdown-item {
  color: #1f2d3d !important;
}

.navbar .notifications i {
  cursor: pointer !important;
}

form i {
  cursor: pointer !important;
}

.checkbox-modal .modal-body .card-body {
  min-height: 300px !important;
}
.modal-content .select-user input[type="checkbox"] {
  transform: scale(1.3);
}

.modal-content .select-user label {
  font-size: 16px;
  color: #22597c;
  padding-left: 5px;
}

/* RIGHT SIDE SLIDER MODAL STYLES */

.control-sidebar {
  position: fixed !important ;
  margin: auto !important;
  width: 310px !important;
  height: 100% !important;
  right: 0px !important;
  z-index: 1050 !important;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.control-sidebar .slider--header h5 {
  margin-bottom: 0.15rem !important;
  font-size: 17px !important;
}

.search--driver .search_form {
  display: none;
}

.control-sidebar .slider-content {
  height: 100% !important;
  overflow-y: auto;
  border-radius: 0;
  border: none;
  max-height: 100vh !important;
}

.control-sidebar .content-body {
  padding: 6px !important;
}

#scrollableDiv {
  overflow: auto;
}

.inline-icon {
  display: inline-block;
  margin-right: 10px;
}

.inline-text {
  display: inline-block;
  vertical-align: middle;
}

.driver--lists .item {
  padding: 10px;
  background: #f1f1f1;
  margin-bottom: 5px;
  cursor: pointer;
}

.driver--lists .item h5 {
  font-size: 14px;
  width: 100%;
  margin-bottom: -5px;
}

.driver--lists .item i {
  width: 35px;
  aspect-ratio: 1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.driver--lists .item .menu-info {
  display: flex;
  align-items: start;
  gap: 5px;
  color: #000;
}

.driver--lists .item p {
  margin-bottom: 0px;
}

.complete-order--driver {
  background: green;
  border-radius: 40px;
  color: #fff;
  width: 22px;
  aspect-ratio: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  padding: 6px;
}

.modal.right .center-content {
  text-align: center;
}

.on-drag {
  border: solid 2px gold;
  background-color: #000;
}

/* CUSTOPM CONFIRM MODAL */

.custom-ui {
  text-align: center;
  margin-bottom: 100px;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
  display: block;
  font-size: 1.6em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 600;
}

.custom-ui > p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

table tr .day-name {
  color: rgb(76, 201, 240);
  font-size: 17px;
}

/* ---- View Comments Modal Stylea--------- */

.card-no-border .card {
  border: 0px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.comment-widgets .comment-row {
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.comment-widgets h5 {
  font-size: 1.15rem !important;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #03aad7;
}

.comment-widgets .comment-row {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding: 10px;
}
.comment-text:hover {
  visibility: hidden;
}
.comment-text:hover {
  visibility: visible;
}

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.round img {
  border-radius: 100%;
}

.label-info {
  background-color: #1976d2;
}

.label-success {
  background-color: green;
}

.label-danger {
  background-color: #ef5350;
}

.action-icons a {
  padding-left: 7px;
  vertical-align: middle;
  color: #99abb4;
}

.action-icons a:hover {
  color: #1976d2;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

table tbody .cursor-pointer {
  cursor: pointer;
}

table tbody td span {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-dialog .cursor-pointer {
  cursor: pointer;
}

.all_drivers .table_card .status--bold {
  font-weight: 600;
}

.all_drivers th.input--checkbox,
.all_drivers th.serial--number {
  width: 80px;
}

.all_drivers th.user--name {
  width: 260px;
}

.all_drivers th.phone--number {
  width: 200px;
}

.all_drivers th.created--date {
  width: 220px;
}

.all_drivers th.driver--balance {
  width: 130px;
}

.all_drivers th.availability,
.all_drivers th.online-driver,
.all_drivers th.active-driver {
  width: 130px;
}

.time--line--card {
  margin: 0px 30px 0px 30px;
}

.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem;
}

.timeline--stamp {
  padding-left: 5px;
  color: #adb5bd;
  font-size: 0.8989rem;
  white-space: nowrap;
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: 0.25rem;
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 0.8s;
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative;
}
.badge:empty {
  display: block !important;
}

.badge-dot-xl::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff;
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
  font-size: 0.8rem;
}

.vertical-timeline-element-content .timeline-title {
  text-transform: capitalize;
  font-size: 0.9rem;
  margin: 0 0 0.5rem;
  padding: 2px 0 0;
  font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 0;
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: 0.7619rem;
  white-space: nowrap;
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}

table .tooltip-text {
  position: absolute;
  min-width: 350px;
  background-color: #0c0f0a;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
}
table .tooltip-text p {
  font-size: 13px;
  text-align: center;
}

@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
  }
}

.checkbox.checbox-switch {
  padding-left: 0;
}

.checkbox.checbox-switch label,
.checkbox-inline.checbox-switch {
  display: inline-block;
  position: relative;
  padding-left: 0;
}

.checkbox.checbox-switch label input,
.checkbox-inline.checbox-switch input {
  display: none;
}

.checkbox.checbox-switch label span,
.checkbox-inline.checbox-switch span {
  width: 39px;
  border-radius: 20px;
  height: 18px;
  border: 1px solid #dbdbdb;
  background-color: rgb(255, 0, 0); /* Set to red for unchecked state */
  border-color: rgb(255, 0, 0); /* Set to red for unchecked state */
  box-shadow: rgb(255, 0, 0) 0px 0px 0px 0px inset; /* Set to red for unchecked state */
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s,
    background-color 1.2s ease 0s;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.checkbox.checbox-switch label span:before,
.checkbox-inline.checbox-switch span:before {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  content: " ";
  top: 0;
  position: relative;
  left: 0;
  transition: all 0.3s ease;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}

.checkbox.checbox-switch label > input:checked + span:before,
.checkbox-inline.checbox-switch > input:checked + span:before {
  left: 22px;
}

.checkbox.checbox-switch.switch-success label > input:checked + span,
.checkbox-inline.checbox-switch.switch-success > input:checked + span {
  background-color: rgb(40, 167, 69);
  border-color: rgb(40, 167, 69);
  box-shadow: rgb(40, 167, 69) 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s,
    background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-success label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-success > input:checked:disabled + span {
  background-color: rgb(153, 217, 168);
  border-color: rgb(153, 217, 168);
  box-shadow: rgb(153, 217, 168) 0px 0px 0px 8px inset;
}

.checkbox.checbox-switch.switch-success label > input:disabled + span,
.checkbox-inline.checbox-switch.switch-success > input:disabled + span {
  background-color: rgb(255, 0, 0); /* Red color for unchecked disabled state */
  border-color: rgb(255, 0, 0); /* Red color for unchecked disabled state */
  box-shadow: rgb(255, 0, 0) 0px 0px 0px 8px inset; /* Red color for unchecked disabled state */
}

.wallet--modal {
  max-width: 600px !important;
}

.wallet--modal .wallet-form-group label {
  font-size: 17px;
  min-height: 40px;
}

.wallet--modal .wallet-form-group .user-name {
    font-size: 16px;
    margin-bottom: 12px;
}

.wallet--modal .wallet-body{
  background-color: rgb(245, 245, 245)
}

.wallet--modal .wallet-body .form-control {
  height: 46px 
}

.active-payment-method {
  border: "1px solid #48A0DB";
  background: #e5e5e5 !important;
  color : #000000,
}

.active-payment-method h4 {
  font-weight: 600 !important ;
  font-size: 15px !important;
}


/* ### ---SIGN UP FORM ----### */

#grad1 {
  background-color:  #002642;
  height: 100vh;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
}

/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;

  /*stacking fieldsets above each other*/
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9E9E9E;
}

#msform input, #msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus, #msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

/*Blue Buttons*/
#msform .action-button {
  width: 100px;
  background: skyblue;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover, #msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

/*Previous Buttons*/
#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

/*Dropdown List Exp Date*/
select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

/*The background card*/
.signup--content .card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.signup--content .form-group .form-control{
	min-height: 45px;
	background: #F5F5F5 !important;
}

/*FieldSet headings*/
.fs-title {
  font-size: 20px;
  color: #2C3E50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

/*Icons in the ProgressBar*/
#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #mapLocation:before {
  font-family: FontAwesome;
  content: "\f041";
}

#progressbar #otpVerification:before {
  font-family: FontAwesome;
  content: "\f084"; /* Key Icon */
}



/*ProgressBar before any progress*/
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before, #progressbar li.active:after {
  background: skyblue;
}

/*Imaged Radio Buttons*/
.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display:inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor:pointer;
  margin: 8px 2px; 
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

/*Fit image in bootstrap div*/
.fit-image{
  width: 100%;
  object-fit: cover;
}

.hour--details .nav-tabs {
  justify-content: center;
  border: 0;
}
.hour--details .tab-content{
text-align: center;
  margin-top: 2rem;
}
.hour--details a.nav-link {
  font-size: 18px;
  color: #495057;
  padding: 4px 22px
}
.hour--details a.nav-link.active {
  border-color: transparent transparent #4fa3cb;
  border-bottom: 3px solid #4fa3cb !important;
  transition: 0.5s;
}

/* .hour--details input {
  border: 1px solid #dee2e6;
  padding: 10px 16px;
  line-height: 1.33;
  border-radius: 6px;
  color: rgb(0 0 0 / 85%);
  text-transform: uppercase;
} */

input.calendar-sec {
  margin-left: 15px;
}
@media only screen and (max-width: 992px) {
  input.calendar-sec {
    margin-left: 0;
    margin-top: 15px;
}
}

.img-size {
  max-width: 100px !important;
  min-height: 100px;
  width: auto;
  height: auto;
}

.button_and_dot_container{
  width: 100%;
  background-color: rgba(0, 0, 0, .1);
}
.inner-wrapper{
  max-width: 60%;
  width: 100%;
  margin-left: auto;
  margin-top: 6px;
  display: flex;
}

.button_and_dot_container .inner-wrapper .text-white {
  width: 80%;
  text-align: left;
}

.action_button .btn-group .dropdown-menu{
  left: -50px !important;
}

.action_button {
  width: 20%;
}
.action--btn{
float: right;
margin-right: 11px;
}
.my--row{
  width: 100%;
}
.btn-group {
  width: 20px;
}
.tab--container{
  padding-bottom: 20px;
}